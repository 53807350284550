








































































import { InitError } from '@openticket/sdk-shop';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ClosedView extends Vue {
    showErrors = false;

    async created(): Promise<void> {
        await this.$shop.messaging.connecting;
        this.showErrors = this.$shop.messaging.client.type === 'dashboard';
    }

    get errors(): InitError[] {
        return this.$shop.initErrors;
    }
}
