












































import Vue from 'vue';
import Component from 'vue-class-component';
import CardHeading from './CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import { Prop } from 'vue-property-decorator';
import { Event } from '@openticket/sdk-shop';

@Component({
    components: {
        CardHeading,
        DateBlock,
    },
})
export default class EventCard extends Vue {
    @Prop() event!: Event;
}
