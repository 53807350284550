










































































































































import Vue, { VueConstructor } from 'vue';
import Component from 'vue-class-component';
import ShopHeader from '../components/ShopHeader.vue';
import { Action, State } from 'vuex-class';
import ShopModuleComponent from '../modules/module';
import OrderBar from '../components/OrderBar.vue';
import SplashScreen from '../../../components/SplashScreen.vue';
import { Watch } from 'vue-property-decorator';
import '../modules';
import { scrollTo, scrollToOffset } from '@/utils';
import TimeslotsFilter from '../modules/Timeslots.vue';
import { ShopDates, ShopPage } from '../store/types';
import isOpenTicketLogMessage from '@/utils/is_log_message';
import { LogMessage, ValidationResponse } from '@openticket/sdk-shop';
import { AxiosError } from 'axios';
// import * as rudderanalytics from 'rudder-sdk-js';

const ORDER_SLOW_THRESHOLD = 5000;

@Component({
    components: {
        ShopHeader,
        OrderBar,
        TimeslotsFilter,
        SplashScreen,
    },
})
export default class PagesView extends Vue {
    @State('pages') pages!: ShopPage[];
    @State('dates') dates!: ShopDates;
    @Action('nextPage') actionNextPage!: () => void;

    title = '';
    valid = false;
    loading = false;
    placingOrder = false;
    placeOrderSuccess = false;

    showTimeslotWarning = false;

    paymentUrl = '';
    orderRedirectIsSlow = false;

    created(): void {
        this.onPageChange();
    }

    get page(): ShopPage {
        const name = this.$route.params.page;
        return this.pages.find(
            (page: ShopPage) => page.name === name
        ) as ShopPage;
    }

    get index(): number {
        const name = this.$route.params.page;
        return this.pages.findIndex((page: ShopPage) => page.name === name);
    }

    get prevPage(): ShopPage | undefined {
        const name = this.$route.params.page;
        const index = this.pages.findIndex(
            (page: ShopPage) => page.name === name
        );
        return this.pages[index - 1];
    }

    get nextPage(): ShopPage | undefined {
        const name = this.$route.params.page;
        const index = this.pages.findIndex(
            (page: ShopPage) => page.name === name
        );
        return this.pages[index + 1];
    }

    get showTimeslots(): boolean {
        return this.index === 0 && !!this.$shop.data.greedy_date_selection;
    }

    refreshShop(): void {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    }

    getModules(): ShopModuleComponent[] {
        const components: any[] = [];

        for (let i = 0; i < (this.page.modules || []).length; i++) {
            let elem = this.$refs[`shop_module_${i}`];

            if (Array.isArray(elem)) {
                elem = elem[0];
            }

            if (elem) {
                components.push(elem);
            }
        }

        return components;
    }

    next(): void {
        if (this.showTimeslots && !this.validateTimeslots()) {
            return;
        }

        const valid = this.validatePage(true);

        if (valid) {
            this.actionNextPage();
        }
    }

    validateTimeslots(): boolean {
        if (
            !this.$settings ||
            !this.$settings.static ||
            !this.$settings.static.shop.enableTimeslotsFilters
        ) {
            return true;
        }

        if (!this.$route.query.eventDate) {
            // Simulate shop-module error
            this.showTimeslotWarning = true;
            this.$notifications.danger(
                this.$t('shop.components.shop.select_timeslot') as string
            );
            scrollTo({
                element: this.$refs.timeslots as HTMLDivElement,
            });

            setTimeout(() => {
                this.showTimeslotWarning = false;
            }, 750);

            return false;
        }

        return true;
    }

    async placeOrder(): Promise<void> {
        const valid = this.validatePage(true);

        if (!valid) {
            return;
        }

        this.placingOrder = true;

        let orderSuccessful = false;

        try {
            await this.$shop.cart.finalizePaymentMethod();

            const paymentMethodValidation: ValidationResponse = this.$shop.cart.validator.paymentMethod(
                true,
                true
            );

            if (!paymentMethodValidation.valid) {
                const modules: ShopModuleComponent[] = this.getModulesByScopes([
                    'payment',
                ]);

                if (modules[0]) {
                    modules[0].isDirty = false;
                }

                this.showError(paymentMethodValidation.message, modules);

                return;
            }

            const response = await this.$shop.cart.placeOrder();

            orderSuccessful = true;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.paymentUrl = response.redirectUrl;

            // rudderanalytics.track(
            //     'order_submitted',
            //     {
            //         // @ts-expect-error - response type not defined yet
            //         order_id: response?.order_id,
            //     },
            //     {
            //         context: {
            //             traits: {
            //                 shop_id: this.$shop.data.guid,
            //             },
            //         },
            //     }
            // );

            // Show actions when redirect is taking a long time
            setTimeout(() => {
                this.orderRedirectIsSlow = true;
            }, ORDER_SLOW_THRESHOLD);

            // If the shop is loaded inside an iframe, overwrite the location of its parent
            if (window.top !== window.self) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.top.location = response.redirectUrl;

                // Else, overwrite its own location
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.location = response.redirectUrl;
            }
        } catch (e) {
            // We do not want anything but processed errors!
            if (!isOpenTicketLogMessage(e)) {
                this.$shop.notifications.send({
                    message: `${e}`,
                    type: 'is-danger',
                });

                throw e;
            }

            this.processPlaceOrderLogMessage(e);
        } finally {
            if (!orderSuccessful) {
                // Enable order button again
                this.placingOrder = false;
            }
        }
    }

    onDirtyEvent(): void {
        this.valid = this.validatePage(false);
    }

    validatePage(mutate?: boolean): boolean {
        const modules = this.getModules();

        for (let i = 0; i < modules.length; i++) {
            const module = modules[i];

            const config = this.page.modules[i];

            // Check for validation errors
            let error = module.validate(mutate);

            module.isDirty = false;

            if (error) {
                if (mutate) {
                    const message = error.slug
                        ? (this.$t(error.slug, error.slugData) as string)
                        : error.message;

                    this.showError(message, [module]);
                }
                return false;
            }

            const comp = this.getVueShopModuleComponent(config.name);

            // For a component to be valid, it should also be ready
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error = comp.isReady ? comp.isReady(this.$shop) : null;

            if (error) {
                if (mutate) {
                    this.showError('Something went wrong', [module]);
                }
                return false;
            }
        }

        return this.nextPageReady(mutate);
    }

    nextPageReady(mutate?: boolean): boolean {
        if (this.nextPage) {
            for (const comp of this.nextPage.modules) {
                const elem = this.getVueShopModuleComponent(comp.name);

                if (!elem) {
                    throw Error(`Module "${comp.name}" not found`);
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const error = elem.isReady ? elem.isReady(this.$shop) : null;

                if (error) {
                    if (mutate) {
                        const modules = this.getModulesByScopes(error.scopes);

                        const message = error.slug
                            ? (this.$t(error.slug, error.slugData) as string)
                            : error.message;

                        this.showError(message, modules);
                    }
                    return false;
                }
            }
        }
        return true;
    }

    showError(error: string, modules: ShopModuleComponent[]): void {
        this.$shop.notifications.send({
            message: error,
            type: 'is-danger',
        });

        const cls = 'shop-module-focus';
        const regex = new RegExp('(\\s|^)' + cls + '(\\s|$)');

        for (let i = 0; i < modules.length; i++) {
            const module = modules[i];
            const element = module.$el;

            if (module && element) {
                // Scroll to the first element
                if (i === 0) {
                    const offset =
                        module.$el.getBoundingClientRect().top + window.scrollY;

                    scrollToOffset(Math.max(offset - 80, 0));
                }

                element.className += ' ' + cls;

                setTimeout(() => {
                    element.className = element.className.replace(regex, ' ');
                }, 750);
            }
        }
    }

    // noinspection JSMethodCanBeStatic
    getVueShopModuleComponent(name: string): VueConstructor {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Vue.options.components[`shop-module-${name}`];
    }

    getModulesByScopes(scopes: string[]): ShopModuleComponent[] {
        let modules = this.getModules();

        modules = modules.filter(
            (module: ShopModuleComponent) =>
                module.scopes.filter((scope: string) => scopes.includes(scope))
                    .length > 0
        );

        return modules;
    }

    processPlaceOrderLogMessage(e: LogMessage): void {
        let scope!: string;
        let message!: string;

        if (
            // Specifically handle stripe errors
            e.slug ===
            'sdkshop.log.cart.finalizePaymentMethod.error.stripe.error'
        ) {
            scope = 'payment';
            message = this.$t(e.slug) as string;

            if (e.data.message) {
                message += ` ${e.data.message}`;
            }
        } else if (e.exception) {
            const exception: AxiosError = e.exception as AxiosError;

            if (
                // Check if the response data has the expected format
                exception?.response?.data?.errors?.length &&
                exception.response.data.errors[0]
            ) {
                // Get the scope and message based on the subjectType
                switch (exception.response.data.errors[0].subjectType) {
                    case 'Payment':
                        scope = 'payment';
                        message = this.$t(
                            exception.response.data.errors[0].reason
                        ) as string;

                        break;
                    case 'Ticket':
                        scope = 'tickets';
                        message = this.$t(
                            exception.response.data.errors[0].reason
                        ) as string;

                        break;
                    default:
                        this.$shop.notifications.send({
                            message: JSON.stringify(
                                exception.response.data.errors[0]
                            ),
                            type: 'is-danger',
                        });

                        throw e;
                }
            }
        }

        // If at least one of them is unknown, the context is unknown
        if (!scope || !message) {
            this.$shop.notifications.send({
                message: this.marshalPlaceOrderErrorDescription(e),
                type: 'is-danger',
            });

            throw e;
        }

        // Find the components that are to blame for the error
        const components = this.getModulesByScopes([scope]);

        if (!components || components.length < 1) {
            this.$shop.notifications.send({
                message,
                type: 'is-danger',
            });

            throw e;
        }

        // If found, show the error to the user
        this.showError(message, components);
    }

    private marshalPlaceOrderErrorDescription(e: any): string {
        if (!e._isOpenTicketLogMessage) {
            return `${e}`;
        }

        if (!e.exception?.response?.data?.error_description) {
            return `${e}`;
        }

        const errorDescription = e.exception.response.data.error_description;

        if (typeof errorDescription === 'string') {
            return this.$t(errorDescription) as string;
        }

        if (Array.isArray(errorDescription) && errorDescription.length > 0) {
            return errorDescription
                .map((item): string => {
                    return this.$t(`${item}`) as string;
                })
                .join(', ');
        }

        if (
            Object.getOwnPropertyNames(errorDescription) &&
            Object.getOwnPropertyNames(errorDescription).length
        ) {
            return Object.getOwnPropertyNames(errorDescription)
                .map((key: string) => {
                    const val = errorDescription[key];

                    if (!Array.isArray(val) || !val.length) {
                        return this.$t(`${val}`) as string;
                    }

                    return val
                        .map((item): string => {
                            return this.$t(`${item}`) as string;
                        })
                        .join(', ');
                })
                .join(', ');
        }

        return `${e}`;
    }

    @Watch('page')
    onPageChange(): void {
        this.valid = false;
        this.$nextTick(() => {
            this.title = this.page.title;
            this.onDirtyEvent();
        });
    }
}
