
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PaymentMethod } from '@openticket/sdk-shop';

@Component
export default class PaymentMethodItemIcon extends Vue {
    @Prop() method!: PaymentMethod;

    demoPaymentUrl: string | null = null;

    listener!: number;

    created(): void {
        this.setDemoPaymentUrl();

        this.listener = this.$style.addThemeListener(() => {
            this.setDemoPaymentUrl();
        });
    }

    destroyed(): void {
        if (this.listener !== undefined) {
            this.$style.removeThemeListener(this.listener);
        }
    }

    setDemoPaymentUrl(): void {
        switch (this.$style.getAppliedTheme()) {
            case 'light':
                this.demoPaymentUrl = this.$whitelabel.resources.icon;
                break;
            case 'dark':
                this.demoPaymentUrl = this.$whitelabel.resources.iconDark;
                break;
        }
    }
}
