var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop"},[_c('ShopHeader',{attrs:{"title":_vm.title,"index":_vm.pages.indexOf(_vm.page) + 1,"page-count":_vm.pages.length}}),_c('div',{staticClass:"shop__content"},[_c('transition',{attrs:{"name":"page-fade"}},[_c('div',{key:_vm.page.name},[_c('div',{ref:"timeslots"},[(_vm.showTimeslots)?_c('TimeslotsFilter',{class:{
                            'shop-module-focus': _vm.showTimeslotWarning,
                        },on:{"loading":function (val) { return (_vm.loading = val); }}}):_vm._e()],1),_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"shop__content__modules"},_vm._l((_vm.page.modules || []),function(comp,i){return _c(("shop-module-" + (comp.name)),_vm._b({key:i,ref:("shop_module_" + i),refInFor:true,tag:"component",on:{"isDirty":function($event){return _vm.onDirtyEvent(i)}}},'component',comp,false))}),1)]),_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"spinner__container"},[_c('div',{staticClass:"ot-spinner"})])])]),_c('OrderBar',{directives:[{name:"show",rawName:"v-show",value:(
                        (_vm.page.modules || []).length > 0 &&
                            _vm.page.name !== 'events'
                    ),expression:"\n                        (page.modules || []).length > 0 &&\n                            page.name !== 'events'\n                    "}],ref:"order_bar",attrs:{"page":_vm.page,"valid":_vm.valid,"visible":(_vm.page.modules || []).length > 0,"disabled":_vm.placingOrder,"has-next-page":!!_vm.nextPage},on:{"next":_vm.next,"place-order":_vm.placeOrder}})],1)])],1),_c('SplashScreen',{attrs:{"visible":_vm.placingOrder,"label":!_vm.orderRedirectIsSlow
                ? _vm.$t('shop.components.shop.placing_order')
                : _vm.$t('shop.components.shop.placing_order_slow')}},[(_vm.orderRedirectIsSlow)?_c('div',{staticClass:"shop__splash-actions"},[_c('a',{attrs:{"href":_vm.paymentUrl}},[_c('button',{staticClass:"ot-button"},[_vm._v(" "+_vm._s(_vm.$t('shop.components.shop.placing_order_try_again'))+" "),_c('i',{staticClass:"ot-button-icon--right oti oti-arrow-right"})])]),_c('div',{staticClass:"ot-link",on:{"click":_vm.refreshShop}},[_vm._v(" "+_vm._s(_vm.$t('shop.components.shop.placing_order_back_to_shop'))+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }