
import {
    CartItemsEvent,
    CartTicket,
    GroupProduct,
    PricingMethod,
    ShopProduct,
} from '@openticket/sdk-shop';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ShopGroupItem from '../ShopGroupItem.vue';

@Component
export default class OptionalProductGroupOptionalItem extends ShopGroupItem {
    @Prop() ticket!: CartTicket;
    @Prop() product!: GroupProduct;

    public created(): void {
        this.title = this.product.item.name;

        // The pricing should come from the ShopProduct wrapper,
        // the GroupProduct item's pricing is static,
        // for container products, the ShopProduct's pricing will change
        // depending on the products in the cart!
        this.pricing = (
            this.ticket.item.products.all.find(
                (product: ShopProduct) => product.guid === this.product.guid
            ) || this.product.item
        ).pricing;

        this.description = this.product.item.description;

        this.status = 'available';

        const data = this.$shop.cart.getTicketProductInfo(
            this.ticket.id,
            this.product.guid
        );

        this.update(data);

        this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product', this.product.guid],
            (path: string[], event: CartItemsEvent) => this.update(event)
        );

        if (this.product.item.pricing_method === PricingMethod.Container) {
            // The pricing of container products can change async if any
            // own priced products are added to the ticket.
            // Currently there is no specific event when this update happens.
            // Therefore listening to updates on the ticket GUID instead.
            // This could be optimised once a specific event is available.
            this.$shop.cart.on(
                ['ticket', this.ticket.item.guid, 'update'],
                () => {
                    this.update(
                        this.$shop.cart.getTicketProductInfo(
                            this.ticket.id,
                            this.product.guid
                        )
                    );
                }
            );
        }
    }

    protected add(): Promise<CartItemsEvent> {
        return this.$shop.cart.addProductToTicket(
            this.ticket.id,
            this.product.guid
        );
    }

    protected remove(): Promise<CartItemsEvent> {
        return this.$shop.cart.removeProductFromTicket(
            this.ticket.id,
            this.product.guid
        );
    }
}
