



















































































import ShopModule, { ShopModuleValidationError } from './module';
import Component from 'vue-class-component';

@Component
export default class TermsModule extends ShopModule {
    /* SHOP MODULE */
    public scopes = ['terms'];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const response = this.$shop.cart.validator.allTerms(mutate);

        if (response.valid) {
            return null;
        }

        return {
            message: 'Terms should be accepted',
            slug: 'shop.components.terms.should_be_accepted_error',
            payload: response.errors,
        };
    }

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    whitelabelName: string | null = null;
    whitelabelTerms: string | null = null;
    companyName: string | null = null;
    companyTerms: string | null = null;
    hasCompanyTerms = false;

    created(): void {
        this.whitelabelName = this.$shop.data.branding.name;
        this.whitelabelTerms = this.$shop.data.branding.shop_terms;
        this.companyName =
            this.$shop.data.company.coc_name || this.$shop.data.company.name;
        this.companyTerms = this.$shop.data.company_terms;

        if (
            typeof this.companyTerms !== 'undefined' &&
            this.companyTerms !== null
        ) {
            this.hasCompanyTerms = true;
        }
    }

    get terms(): { [key: string]: boolean } {
        return this.$shop.cart.terms;
    }

    get termsUrl(): string {
        const locale = this.$localization.locale.locale;
        const separator = (this.whitelabelTerms || '').includes('?')
            ? '&'
            : '?';
        return `${this.whitelabelTerms}${separator}lang=${locale}`;
    }

    async onInput(term: string, value: InputEvent): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await this.$shop.cart.agreeToTerms(term, value.target.checked);
        this.isDirty = true;
    }
}
