













import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import { Mutation, State } from 'vuex-class';
import { SET_PAGES } from './store';
import {
    EventSelection,
    IShopConfig,
    LogLevel,
    LogMessage,
    ReservationExpiration,
    ShopFilter,
    State as ShopState,
} from '@openticket/sdk-shop';
import { hasLocalStorage, LocalStorage, openModal } from '../../utils';
import TimeoutModal from './modals/Timeout.vue';
import { STORAGE_PAYMENT_METHOD_KEY } from './modules/Payment.vue';
import Footer from '@/components/Footer.vue';
import { Inject } from 'vue-property-decorator';
import {
    IShopSettingsPage,
    IShopSettingsStatic,
} from '@openticket/lib-custom-shop-settings';
import defaultPages from './pages.json';
import { BaseInit, OverlayManager } from '@/Base.vue';
import { StyleTheme } from '@openticket/lib-style';
import { NotificationConfig } from '@openticket/vue-notifications';
import ClosedView from './views/Closed.vue';
import Tracking from './Tracking.vue';
import { ShopTweakConfigPage } from './store/types';

const DEFAULT_SHOP_API_URL = 'https://shop.api.openticket.tech';

interface CachedPaymentMethod {
    guid: string;
    issuer?: string;
}

@Component({
    components: {
        ClosedView,
        Tracking,
        Footer,
    },
})
export default class ShopView extends Vue {
    @Mutation(SET_PAGES)
    setPages!: (pages: ShopTweakConfigPage[]) => void;

    @State('pages')
    pages!: ShopTweakConfigPage[];

    @Inject('overlay')
    overlay!: OverlayManager;

    @Inject('baseInit')
    baseInit!: BaseInit;

    waiting = false;
    initialized = false;
    initError: LogMessage | null = null;

    closeOverlay!: () => void;

    async created(): Promise<void> {
        this.closeOverlay = this.overlay.show();

        // Listen for notification events, only show when not embedded
        this.$shop.events.on(
            ['notification'],
            (_path: string[], data: NotificationConfig) => {
                let notification!: NotificationConfig;

                if (data.slug) {
                    if (typeof data.slug === 'string') {
                        notification = {
                            type: data.type,
                            message: this.$t(
                                data.slug,
                                data.slugData
                            ) as string,
                        };
                    } else {
                        notification = {
                            type: data.type,
                            message: {
                                text: this.$t(
                                    data.slug.text,
                                    data.slugData
                                ) as string,
                                subText: this.$t(
                                    data.slug.subText,
                                    data.slugData
                                ) as string,
                            },
                        };
                    }
                } else {
                    if (typeof data.message === 'string') {
                        notification = {
                            type: data.type,
                            message: data.message,
                        };
                    } else {
                        notification = {
                            type: data.type,
                            message: data.message,
                        };
                    }
                }

                if (!this.$shop.isFramed) {
                    this.$notifications.show(notification);
                } else {
                    this.$shop.sendClientNotification(notification);
                }
            }
        );

        this.$shop.events.on(
            ['settings', 'static'],
            (_, settings: IShopSettingsStatic) => {
                if (this.$settings) {
                    this.$settings.localUpdateStaticSettings(settings);

                    this.$style.setTheme(
                        this.$settings.static.theme as StyleTheme
                    );

                    if (this.$settings.static.style) {
                        this.$style.setStyle(this.$settings.static.style);
                    }
                }
            }
        );

        const shop_id = this.$route.params.shop_id;

        const baseInitPromise = this.baseInit(shop_id);

        // Create config object with shop id from the URL
        const config: IShopConfig = this.createConfig(shop_id);

        // Create filters for dates if the shop is a timeslot shop
        const filters: ShopFilter | undefined = this._determineFilters();

        // Start initialization of the shop
        try {
            // Setup the shop structure and fetch the initial shop data
            await this.$shop.init(config, filters);
        } catch (error) {
            this.closeOverlay();

            // Ignore any errors here, they will be handled by _finishInitialization
            this.waiting = this.$shop.state === ShopState.Waiting;

            if (
                error &&
                error._isOpenTicketLogMessage &&
                error.slug === 'sdkshop.log.shop.init.error.fatal' &&
                error.parent &&
                error.parent.slug === 'sdkshop.log.shop.data.error.notfound'
            ) {
                await this.$router.push({
                    name: 'error',
                    query: { redirect: this.$route.path },
                });

                return;
            }

            if (!this.waiting) {
                // TODO Show some kind of error???
                //  as the loading screen is shown in this case...
                //  probably show some overlay like dashboard ???

                await this.$router.push({
                    name: 'error',
                    query: { redirect: this.$route.path },
                });
            }

            throw error;
        }

        // Set the initial document title early
        // Will already update the page title if the shop is only partially initialized.
        if (this.$shop.data && this.$shop.data.name) {
            document.title = this.$shop.data.name;
        }

        await baseInitPromise;

        // Set some properties based on the tweaks
        this.initShopPages();

        // Let component initialization finish!
        this.$nextTick(() => {
            this._finishInitialization();
        });
    }

    async _finishInitialization(): Promise<void> {
        try {
            // Await shop initialization (this will also wait for suspended initialization)
            await this.$shop.initialized;
        } catch (error) {
            this.initError = error;

            console.log('errr', error);

            this.closeOverlay();

            if (this.$shop.initErrors.length) {
                await this.$router.push({
                    name: 'closed',
                    query: {
                        redirect: this.$route.path,
                    },
                });
            } else {
                await this.$router.push({
                    name: 'error',
                    query: {
                        redirect: this.$route.path,
                    },
                });
            }

            return;
        } finally {
            this.waiting = false;
        }

        this.initError = null;

        this.$shop.cart.setLocale(this.$localization.locale.locale);

        await this._checkCouponParam();

        this._checkShopCodeParam();

        if (this.$shop.data.company.timezone) {
            // Shop is loaded here, forward spain to old tickets shops
            let companyIds = [
                '715c7680-7dd2-11eb-be0b-510d4be45e8f',
                'c53172b0-d3fd-11eb-a836-3d672b8deb90',
                'eebba8b0-d82a-11eb-b434-dd53393e5f0b',
            ];

            if (companyIds.includes(this.$shop.data.company.guid || '')) {
                window.location.href =
                    'https://shop0.eventix.io/' + this.$shop.data.guid;
            }

            this.$localization.setTimezone(this.$shop.data.company.timezone);
        }

        // TODO Add shop data locale to localize defaults -> which should trigger a change if it needs to!.

        // (Re-)set the document title
        document.title = this.$shop.data.name || '';

        this.$shop.events.on(
            ['timer', 'clear'],
            async (
                path: string[],
                data: ReservationExpiration & { timeout: boolean }
            ) => {
                if (!data.timeout) {
                    return;
                }

                await this.pushFirstPage();

                openModal({
                    component: TimeoutModal,
                    parent: this,
                });
            }
        );

        await this.useLastUsedPaymentMethod();

        try {
            await this.pushFirstPage();
        } finally {
            this.initialized = true;
        }

        if (this.$route.query.sessionId) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    sessionId: undefined,
                },
            });
        }

        this.$nextTick(() => {
            this.closeOverlay();
        });
    }

    /**
     *  Used to check if a coupon param has been set, and add the coupon if so.
     */
    async _checkCouponParam(): Promise<void> {
        const coupon = this.$route.query.coupon as string | null;

        if (!coupon || !coupon.length) {
            return;
        }

        try {
            await this.$shop.cart.addCoupon(coupon);
        } catch {
            // Fail silently when the coupon seems invalid
        }
    }

    /**
     *  Used to check if the shop_code param has been set, and
     *  set it as tech data in the shop (SDK) if so.
     */
    _checkShopCodeParam(): void {
        const shop_code = this.$route.query.shop_code as string | null;

        if (!shop_code || !shop_code.length) {
            return;
        }

        this.$shop.cart.setTechData({ shop_code });
    }

    async pushFirstPage(): Promise<Route | void> {
        try {
            return await this.$router.push({
                name: 'step',
                params: {
                    page: this.pages[0].name,
                },
                query: this.$route.query,
            });
        } catch (e) {
            if (!e.from || !e.to) {
                throw e;
            }

            if (e.from.name !== e.to.name) {
                throw e;
            }

            if (!e.from.params || !e.to.params) {
                throw e;
            }

            if (e.from.params.page !== e.to.params.page) {
                throw e;
            }

            // Ignore navigation duplicated error if the page is the same.
        }
    }

    _determineFilters(): ShopFilter | undefined {
        const query = this.$route.query;

        const filters = ['start', 'end'];

        const map: ShopFilter = {};

        for (const filter of filters) {
            if (query[filter]) {
                map[filter] = query[filter] as string;
            }
        }

        if (Object.keys(map).length < 1) {
            return undefined;
        }

        return map;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Create the basic shop config object
     */
    createConfig(shopId: string): IShopConfig {
        return {
            baseUrl: process.env.VUE_APP_SHOP_API_URL || DEFAULT_SHOP_API_URL,
            guid: shopId,
            logLevels: {
                console: LogLevel.Debug,
                service: LogLevel.Info,
            },
            loggerUrl: process.env.VUE_APP_LOGGER_URL,
        };
    }

    /**
     * Create and initialize the shop tweak data
     */
    initShopPages(): void {
        let pages!: IShopSettingsPage[];

        // Check if ShopSettingsClient exists
        if (
            !this.$settings ||
            !this.$settings.dynamic ||
            !this.$settings.dynamic.shop ||
            !this.$settings.dynamic.shop.pages
        ) {
            // If not, get the default pages
            pages = this.transformShopPages();
        } else {
            // Get pages from custom settings
            pages = this.transformShopPages(this.$settings.dynamic.shop.pages);
        }

        // Set the pages in the vuex instance
        this.setPages(pages);
    }

    /**
     * Transform the pages provided by the tweaks to a more consistent format
     */
    transformShopPages(customPages?: IShopSettingsPage[]): IShopSettingsPage[] {
        const pages: IShopSettingsPage[] = customPages || defaultPages;

        // Loop over all pages to transform all modules with type string to
        // objects containing the name property
        for (const page of pages) {
            const modules = page.modules || [];

            for (let i = 0; i < modules.length; i++) {
                if (typeof modules[i] === 'string') {
                    modules[i] = {
                        name: modules[i] as string,
                    };
                }
            }

            page.modules = modules;
        }

        // Add an events page if the shop is not a timeslot shop
        if (!this.$shop.data.greedy_date_selection && this.hasEventSelection) {
            const eventPage: IShopSettingsPage = {
                name: 'events',
                title: 'shop.pages.shop.events',
                modules: [
                    {
                        name: 'events',
                    },
                ],
            };

            return [eventPage, ...pages];
        }

        return pages;
    }

    get hasEventSelection(): boolean {
        switch (this.$shop.data.event_selection) {
            case EventSelection.Enabled:
                return true;

            case EventSelection.Disabled:
                return false;

            default:
                return (
                    this.$shop.data.events.length > 3 &&
                    !this.$shop.data.greedy_date_selection
                );
        }
    }

    /**
     *  If a payment method was used before,
     *  it is applied to the cart.
     *
     *  Cleans up the cached value if an exception occurs.
     */
    async useLastUsedPaymentMethod(): Promise<void> {
        if (!hasLocalStorage()) {
            return;
        }

        try {
            const cachedMethod: CachedPaymentMethod | null = LocalStorage.get<
                CachedPaymentMethod
            >(STORAGE_PAYMENT_METHOD_KEY);

            if (!cachedMethod) {
                return;
            }

            await this.$shop.cart.setPaymentMethod(
                cachedMethod.guid,
                cachedMethod.issuer
            );
        } catch (e) {
            LocalStorage.remove(STORAGE_PAYMENT_METHOD_KEY);
        }
    }
}
