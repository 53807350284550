






































































import Vue from 'vue';
import Component from 'vue-class-component';
import EventGroupItem from './EventGroupItem.vue';
import CardHeading from './CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import { Prop } from 'vue-property-decorator';
import {
    Collapse,
    Event,
    EventDate,
    EventItem,
    Ticket,
} from '@openticket/sdk-shop';

@Component({
    components: {
        CardHeading,
        EventGroupItem,
        DateBlock,
    },
})
export default class EventGroup extends Vue {
    @Prop() event!: Event;
    @Prop() eventDate!: EventDate | undefined;

    get items(): EventItem[] {
        if (!this.eventDate) {
            return this.event.items.filter((item: EventItem) => !item.hidden);
        }

        const items: EventItem[] = [];
        for (const item of this.event.items) {
            if (item.hidden) {
                continue;
            }

            if (item.type === 'collapse') {
                if (this.showCollapse(item.item as Collapse)) {
                    items.push(item);
                }
            } else {
                if (this.showTicket(item.item as Ticket)) {
                    items.push(item);
                }
            }
        }
        return items;
    }

    showTicket(ticket: Ticket): boolean {
        if (ticket.hidden) {
            return false;
        }

        if (!this.eventDate) {
            return true;
        }

        for (const product of ticket.products.required) {
            for (const event_date of product.item.event_dates) {
                if (event_date.guid === this.eventDate.guid) {
                    return true;
                }
            }
        }

        return false;
    }

    showCollapse(collapse: Collapse): boolean {
        if (!this.eventDate) {
            return true;
        }

        for (const ticket of collapse.tickets) {
            if (this.showTicket(ticket)) {
                return true;
            }
        }
        return false;
    }
}
