
import Component from 'vue-class-component';
import ShopGroupItem from './ShopGroupItem.vue';
import { Prop } from 'vue-property-decorator';
import { CartItemsEvent, Event, ShopProduct } from '@openticket/sdk-shop';
import { AddProductEvent } from '@openticket/sdk-shop';

@Component
export default class ProductGroupItem extends ShopGroupItem {
    @Prop() event!: Event;
    @Prop() product!: ShopProduct;

    created(): void {
        // Set item data
        this.title = this.product.item.name;
        this.pricing = this.product.pricing;
        this.description = this.product.item.description;
        this.status = 'available';

        const data = this.$shop.cart.getProductInfo(this.product.guid);
        this.update(data);

        this.$shop.cart.on(
            ['product', this.product.guid],
            (path: string[], event: AddProductEvent) => this.update(event)
        );
    }

    protected add(): Promise<CartItemsEvent> {
        return this.$shop.cart.addProduct(this.product.guid);
    }

    protected remove(): Promise<CartItemsEvent> {
        return this.$shop.cart.removeProduct(this.product.guid);
    }
}
