
























































































import Component from 'vue-class-component';
import ShopModule from './module';
import { OpenTicketCookiesInjector } from '@openticket/lib-cookies';
import { Ref } from 'vue-property-decorator';

@Component
export default class FacebookPageCard extends ShopModule {
    /* SHOP MODULE */

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Ref('container')
    container!: HTMLDivElement;

    width = 0;

    cookies = new OpenTicketCookiesInjector();
    cookiesAccepted = false;

    async mounted(): Promise<void> {
        const preferences = await this.cookies.init();

        if (preferences && preferences.personalisedAds) {
            this.cookiesAccepted = true;
            this.$nextTick(() => {
                this.setWidth();
            });
        }
    }

    setWidth(): void {
        if (this.appId && this.url && this.cookiesAccepted) {
            this.width = this.container.clientWidth;
        }
    }

    get url(): string | null {
        return this.$shop.data.facebook_page_url;
    }

    get appId(): string | null {
        return this.$whitelabel.shop.facebook_client_id || null;
    }
}
