
































import { Component, Inject, Prop, Ref } from 'vue-property-decorator';
import { Event, EventDate } from '@openticket/sdk-shop';
import EventGroup from '../components/EventGroup.vue';
import ShopModule, { ShopModuleValidationError } from './module';
import TrackingClient, { GTMEvent } from '@openticket/lib-tracking';

@Component({
    components: {
        EventGroup,
    },
})
export default class TicketsView extends ShopModule {
    /* SHOP MODULE */
    public scopes = ['tickets'];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const response = this.$shop.cart.validator.allTickets(mutate);

        if (response.valid) {
            return null;
        }

        return {
            message: response.message,
            payload: response.errors,
        };
    }

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Prop() collapse!: boolean;

    @Ref('tickets') ticketsContainer!: HTMLDivElement;

    @Inject('tracking')
    tracking!: TrackingClient;

    mounted(): void {
        this.$shop.cart.on('ticket', () => {
            this.isDirty = true;
        });

        const shop = {
            guid: this.$shop.data.guid as string,
            name: this.$shop.data.name as string,
        };

        // Send impression events with tickets to tracking client
        for (const event of this.events) {
            const tickets = this.$shop.data.events_map[event.guid].tickets.map(
                ticket => ({
                    id: ticket.guid,
                    pricing: ticket.pricing,
                })
            );

            this.tracking.push(GTMEvent.impression(shop, tickets));
        }
    }

    get show(): boolean {
        if (
            this.$shop.data.greedy_date_selection &&
            this.$settings &&
            this.$settings.static &&
            this.$settings.static.shop.enableTimeslotsFilters
        ) {
            return !!this.eventDate;
        }

        return true;
    }

    get event_id(): string | undefined {
        return this.$router.currentRoute.query.event as string | undefined;
    }

    get event(): Event | undefined {
        if (this.event_id) {
            return this.$shop.data.events_map[this.event_id];
        }
        return undefined;
    }

    get events(): Event[] {
        if (this.event_id) {
            return [this.$shop.data.events_map[this.event_id]];
        }
        return Object.values(this.$shop.data.events);
    }

    get eventDate(): EventDate | undefined {
        const eventDateGuid = this.$route.query.eventDate as string;
        if (!eventDateGuid) {
            return undefined;
        }

        return this.$shop.data.event_dates_map[eventDateGuid];
    }
}
