import { i18n } from '@/base';
import Base from '@/Base.vue';
import Vue from 'vue';
import router from './router';
import store from './store';
import 'iframe-resizer/js/iframeResizer.contentWindow.js';
import { OpenTicketShop } from '@openticket/sdk-shop';
import { Logger } from '@openticket/lib-sdk-helpers';
import VueError from '@/error';

declare module 'vue/types/vue' {
    interface Vue {
        $shop: OpenTicketShop;
        $logger: Logger;
    }
}

declare global {
    interface Window {
        Shop: OpenTicketShop;
    }
}

const sessionId =
    new URLSearchParams(window.location.search).get('sessionId') || undefined;

const logger = (Vue.prototype.$logger = new Logger('shop', {
    sessionId,
}));

// Add shop SDK to vue prototype
Vue.prototype.$shop = window.Shop = Vue.observable(
    new OpenTicketShop({
        sessionId: logger.sessionId,
    })
);

new Vue({
    router,
    store,
    i18n,
    render: h => h(Base),
}).$mount('#app');

Vue.config.errorHandler = err => {
    logger.throw(new VueError('root', err));
};
