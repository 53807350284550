







































































































































import {
    CartMetadata,
    CartProduct,
    CartTicket,
    GroupProduct,
    ProductGroup,
} from '@openticket/sdk-shop';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CheckboxItem from './CheckboxItem.vue';
import MultiSelectItem from './MultiSelectItem.vue';

import OptionalItem from './OptionalItem.vue';
import RadioItem from './RadioItem.vue';
import SelectItem from './SelectItem.vue';

interface ProductMetadata {
    product: GroupProduct;
    metadata: CartMetadata[];
}

@Component({
    components: {
        OptionalItem,
        SelectItem,
        RadioItem,
        MultiSelectItem,
        CheckboxItem,
    },
})
export default class OptionalProductGroup extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;

    @Prop({ required: true, type: Boolean, default: false })
    evenOrOdd!: boolean;

    metadata: ProductMetadata[] = [];

    created(): void {
        this.metadata = this.getMetadata();

        this.$shop.cart.on(['ticket', this.ticket.id], () => {
            this.metadata = this.getMetadata();
        });
    }

    async add(guid: string, oldGuid: string): Promise<void> {
        if (oldGuid) {
            await this.$shop.cart.removeProductFromTicket(
                this.ticket.id,
                oldGuid
            );
        }

        await this.$shop.cart.addProductToTicket(this.ticket.id, guid);
    }

    async remove(guid: string): Promise<void> {
        await this.$shop.cart.removeProductFromTicket(this.ticket.id, guid);
    }

    getMetadata(): ProductMetadata[] {
        if (!this.ticket.children.products) {
            return [];
        }

        return Object.keys(this.ticket.products.map)
            .map((productId: string) => {
                const groupProduct:
                    | GroupProduct
                    | undefined = this.productGroup.products.find(
                    (groupProduct: GroupProduct) =>
                        groupProduct.guid === productId
                );

                if (!groupProduct) {
                    return null;
                }

                return {
                    product: groupProduct,
                    metadata: ([] as CartMetadata[]).concat(
                        ...Object.values(
                            this.ticket.products.map[productId]
                        ).map(
                            (cartProduct: CartProduct) =>
                                cartProduct.metadataList
                        )
                    ),
                };
            })
            .filter(
                (
                    productMetadata: null | ProductMetadata
                ): productMetadata is ProductMetadata =>
                    !!productMetadata && !!productMetadata.metadata.length
            );
    }

    get products(): GroupProduct[] {
        return this.productGroup.products;
    }
}
