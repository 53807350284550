import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import { OpenTicketShop } from '@openticket/sdk-shop';

export interface ShopModuleValidationError {
    module?: string;
    message: string;
    slug?: string;
    slugData?: { [key: string]: string | number };
    payload?: any;
}

export interface ShopModuleNotReady {
    scopes: string[];
    message: string;
    slug?: string;
    slugData?: { [key: string]: string | number };
}

export default class ShopModuleComponent extends Vue {
    public isDirty = false;
    public scopes: string[] = [];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        mutate; // TODO @rik Does this belong here ???
        return null;
    }

    public static isReady: (shop: OpenTicketShop) => ShopModuleNotReady | null;

    @Watch('isDirty')
    protected onChangeIsValid(): void {
        this.$emit('isDirty', this.isDirty);
    }
}
