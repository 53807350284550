































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CardHeading extends Vue {
    @Prop({ type: String }) title!: string;
    @Prop({ type: String }) subtitle!: string;
    @Prop({ default: false }) centered!: boolean;
}
