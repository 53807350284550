















































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Ticket } from '@openticket/sdk-shop';
import { SeatsInfoEvent, SeatsValidationEvent } from '@openticket/sdk-shop';

@Component
export default class SeatsModal extends Vue {
    @Prop() ticket!: Ticket;

    seatsInfo: SeatsInfoEvent | null = null;
    seatsLabel = '';
    isValid = true;

    created(): void {
        this.updateSeatsInfo();

        this.$shop.cart.on(['ticket'], () => {
            this.updateSeatsInfo();
        });

        this.$shop._eventBus.on(
            ['seats', 'validation'],
            (path: string[], data: SeatsValidationEvent) => {
                this.isValid = data.valid;
            }
        );

        this.$shop.cart.on(
            ['timer', 'clear'],
            (path: string[], data: { timeout: boolean }) => {
                if (data.timeout) {
                    this.close();
                }
            }
        );
    }

    async mounted(): Promise<void> {
        const loading = `
    <div class="seats-loader">
        <div class="spinner"></div>
        <h5>Loading...</h5>
    </div>`;

        await this.$shop.cart.addTicketThroughSeats(
            this.ticket.guid,
            '#seats__floorplan',
            {
                loading,
            }
        );
    }

    close(): void {
        this.$shop.cart.closeSeats();

        this.$emit('close');
    }

    updateSeatsInfo(): void {
        this.seatsInfo = this.$shop.cart.getSeatedTicketInfo(this.ticket.guid);

        if (!this.seatsInfo.items || !this.seatsInfo.items.length) {
            this.seatsLabel = '';
        }

        this.seatsLabel = this.seatsInfo.items
            .map(item => item.label)
            .join(', ');
    }
}
