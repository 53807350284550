






import Vue from 'vue';
import Component from 'vue-class-component';
import TrackingClient, { GTMEvent } from '@openticket/lib-tracking';
import { Provide, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import {
    CookiePreferences,
    OpenTicketCookiesInjector as CookiesInjector,
} from '@openticket/lib-cookies';
// import * as rudderanalytics from 'rudder-sdk-js';

@Component
export default class ShopTracking extends Vue {
    @Provide('tracking')
    client = new TrackingClient();

    cookies = new CookiesInjector();

    async created(): Promise<void> {
        try {
            // Wait for SDK to be initialized
            await this.$shop.initialized;

            // Add whitelabel GTM container
            if (this.$whitelabel.shop.gtm_code) {
                void this.client.addGTMContainer(
                    this.$whitelabel.shop.gtm_code
                );
            }

            // Triggered on date selection in timeslot shop
            this.$shop.events.on(
                ['shop', 'impression', 'filter', 'events'],
                (_, data: any) => {
                    this.client.push(
                        GTMEvent.impression(data.shop, data.items)
                    );
                    // skip for now (determine data model to send)
                    // rudderanalytics.track('impression', data.items );
                }
            );

            // Triggered on reserving a ticket
            this.$shop.events.on(['cart'], (path: string[], data: any) => {
                if (path[3] === 'add') {
                    this.client.push([
                        GTMEvent.cartEvent('add', 'products', data),
                        GTMEvent.interaction(
                            window.location.pathname,
                            'Reserve',
                            data.name,
                            data
                        ),
                    ]);
                    // rudderanalytics.track(
                    //     'cart-add',
                    //     {
                    //         count: data.count,
                    //         name: data.name,
                    //         guid: data.guid,
                    //     },
                    //     {
                    //         context: {
                    //             traits: {
                    //                 shop_id: this.$shop.data.guid,
                    //             },
                    //         },
                    //     }
                    // );
                } else if (path[3] === 'remove') {
                    this.client.push([
                        GTMEvent.cartEvent('remove', 'products', data),
                        GTMEvent.interaction(
                            window.location.pathname,
                            'Release',
                            data.name,
                            data
                        ),
                    ]);
                    // rudderanalytics.track(
                    //     'cart-remove',
                    //     {
                    //         count: data.count,
                    //         name: data.name,
                    //         guid: data.guid,
                    //     },
                    //     {
                    //         context: {
                    //             traits: {
                    //                 shop_id: this.$shop.data.guid,
                    //             },
                    //         },
                    //     }
                    // );
                }
            });

            // Initial page load event
            this.pushRouteChange(this.$route, this.$route, {
                events: this.$shop.data.events.map(event => ({
                    guid: event.guid,
                    name: event.name,
                })),
            });

            // Get cookie preferences
            const preferences = await this.cookies.init({
                modal: {
                    position: 'top',
                },
            });

            // Apply preferences or request if not available
            if (preferences) {
                this.cookiePreferencesUpdated(preferences);
            } else {
                this.cookies.on(
                    'accept',
                    (_, newPreferences?: CookiePreferences) => {
                        this.cookiePreferencesUpdated(newPreferences);
                    }
                );

                void this.cookies.openCookiesModal();
            }
        } catch (e) {
            // TODO: log when loggin levels are introduced
            console.warn(e);
        }
    }

    @Watch('$settings.initialized', { immediate: true })
    async settingsInitializedChanged(): Promise<void> {
        if (!this.$settings || !this.$settings.initialized) {
            return;
        }

        try {
            // Wait for SDK to be initialized
            await this.$shop.initialized;

            // If available always load Rudderstack for now
            if (
                this.$settings.dynamic &&
                this.$settings.dynamic.rudderstack &&
                this.$settings.dynamic.rudderstack.writekey &&
                this.$settings.dynamic.rudderstack.dataplaneurl
            ) {
                // rudderanalytics.load(
                //     this.$settings.dynamic.rudderstack.writekey,
                //     this.$settings.dynamic.rudderstack.dataplaneurl,
                //     { sendAdblockPage: true }
                // );
                // // Make sure to remove anonymous_id from url when present
                // rudderanalytics.ready(() => {
                //     this.$router.replace({ query: { ajs_aid: undefined } });
                // });
                //
                // // Make sure to reset all traits and start clean (only leave anonymousId intact)
                // rudderanalytics.reset();
                //
                // // Set new trait with current shop_id
                // rudderanalytics.page(
                //     'shop',
                //     'init',
                //     {},
                //     {
                //         context: {
                //             traits: {
                //                 shop_id: this.$shop.data.guid,
                //             },
                //         },
                //     }
                // );
            }
        } catch (e) {
            // Tracking should never block flow
        }
    }

    /**
     * Apply cookie preferences
     */
    cookiePreferencesUpdated(preferences?: CookiePreferences): void {
        // Add organiser GTM container if base ads allowed
        if (preferences && preferences.baseAds && this.$shop.data.google_tag) {
            void this.client.addGTMContainer(this.$shop.data.google_tag);
        }
    }

    /**
     * Triggered on route changes and on the initial page load
     */
    pushRouteChange(
        to: Route,
        from: Route,
        extra?: Record<string, unknown>
    ): void {
        try {
            // Deduce page title from current route
            let page: string;
            if (to.params.page) {
                page = to.params.page;
            } else {
                page = to.name || to.path;
            }

            // Add shop specific metadata
            const data = {
                ...extra,
                shopPage: page,
                shopName: this.$shop.data.name,
                shopGuid: this.$shop.data.guid,
            };

            // Push to tracking client
            this.client.push([
                GTMEvent.contentView(window.location.pathname, data),
                GTMEvent.history(to.fullPath, from.fullPath),
            ]);

            // Only trigger actual page switches, not change in GET params
            if (to.path !== from.path) {
                // rudderanalytics.page(
                //     'shop',
                //     page,
                //     {
                //         to: to.fullPath,
                //         from: from.fullPath,
                //     },
                //     {
                //         context: {
                //             traits: {
                //                 shop_id: this.$shop.data.guid,
                //             },
                //         },
                //     }
                // );
            }
        } catch (e) {
            // TODO: log when loggin levels are introduced
            console.warn(e);
        }
    }

    @Watch('$route')
    async onRouteChanged(to: Route, from: Route): Promise<void> {
        // Notify shop sdk, only after user invoked router change
        if (from.params.page) {
            this.$shop.events.emit(['router'], {
                new: to.fullPath,
                old: from.fullPath,
            });
        }

        await this.$shop.initialized;
        this.pushRouteChange(to, from);
    }
}
