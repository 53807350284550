



















































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { PricingDetails } from '@openticket/sdk-shop';
import { CartItemsEvent } from '@openticket/sdk-shop';
import { Prop } from 'vue-property-decorator';
import { intToDuration } from '@/utils';

const COUNTDOWN_THRESHOLD = 60 * 30; // In seconds

export type ItemSplit = {
    [count: number]: number;
};

@Component
export default class ShopGroupItem extends Vue {
    @Prop({
        default: false,
    })
    private disableDescription!: boolean;

    @Prop({
        default: false,
    })
    private openDescription!: boolean;

    protected title!: string;
    protected description!: string;
    protected pricing: PricingDetails = {
        base_price: 0,
        total_fees: 0,
        subtotal_price: 0,
        total_price: 0,
    };
    protected status: string | null = null;
    protected type: 'default' | 'seated' = 'default';
    protected groupSize = 1;

    protected countdown: number | null = null;
    protected available_from: Date | null = null;

    protected count = 0;
    protected price = 0;
    protected counts: ItemSplit = [];

    private internalIsLoading = false;
    private timeout: number | null = null;

    protected set isLoading(val: boolean) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (val) {
            this.timeout = window.setTimeout(() => {
                this.internalIsLoading = true;
            }, 300);
        } else {
            this.internalIsLoading = false;
        }
    }

    protected get isLoading(): boolean {
        return this.internalIsLoading;
    }

    protected get countdownString(): string {
        if (this.countdown === null) {
            return '';
        }

        if (this.countdown < 0) {
            return this.$t(
                'shop.common.ticket_status.not_sold_right_now'
            ) as string;
        }

        if (this.countdown < COUNTDOWN_THRESHOLD) {
            return intToDuration(this.countdown, true);
        }

        return this.available_from ? this.$l.dateTime(this.available_from) : '';
    }

    protected get statusSlug(): string {
        switch (this.status) {
            case 'available':
                return 'shop.common.ticket_status.available';
            case 'not_sold_right_now':
                return 'shop.common.ticket_status.not_sold_right_now';
            case 'sold_out':
                return 'shop.common.ticket_status.sold_out';
        }

        // If not recognized, just return the status.
        // This 'slug' will most likely not be present in translation messages
        // and thus be shown as-is.
        return this.status || '';
    }

    private showDescription = false;

    mounted(): void {
        if (
            this.status === 'available' &&
            this.description &&
            this.openDescription
        ) {
            this.showDescription = true;
        }
    }

    protected async add(): Promise<CartItemsEvent> {
        // Implement
        throw Error('ShopGroupItem.add() not implemented');
    }

    protected async remove(): Promise<CartItemsEvent> {
        // Implement
        throw Error('ShopGroupItem.remove() not implemented');
    }

    protected async open(): Promise<void> {
        // Implement
    }

    protected update(event: CartItemsEvent): void {
        this.count = event.count;
        this.counts = event.counts;
        this.price = event.pricing.total_price;
    }

    private async _add() {
        this.isLoading = true;
        this.count = this.count + this.groupSize;

        try {
            const event: CartItemsEvent = await this.add();
            this.update(event);
            this.$emit('change');
        } catch (e) {
            this.count = this.count - this.groupSize;
            let message = '';

            if (e && e._isOpenTicketLogMessage) {
                if (e.slug) {
                    message = this.$t(e.slug, e.data || {}) as string;
                } else if (e.data.exception && e.data.exception.slug) {
                    message = this.$t(
                        e.data.exception.slug,
                        e.data.exception
                    ) as string;
                }
            }

            if (!message.length) {
                message = this.$t(
                    'shop.components.shop_group_item.error.add'
                ) as string;
            }

            this.$shop.notifications.send({
                message,
                type: 'is-danger',
            });
        } finally {
            this.isLoading = false;
        }
    }

    private async _remove() {
        if (this.count < 1) {
            return;
        }

        this.isLoading = true;
        this.count = this.count - this.groupSize;

        try {
            const event: CartItemsEvent = await this.remove();
            this.update(event);
            this.$emit('change');
        } catch (e) {
            this.count = this.count + this.groupSize;
            this.isLoading = false;

            this.$shop.notifications.send({
                message: 'Failed to remove item from cart',
                type: 'is-danger',
            });
        } finally {
            this.isLoading = false;
        }
    }
}
