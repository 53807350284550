































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PaymentMethod, PaymentMethodIssuer } from '@openticket/sdk-shop';
import PaymentMethodItemIcon from './PaymentMethodItemIcon.vue';
import { CartPaymentMethod } from '@openticket/sdk-shop';

@Component({
    components: {
        PaymentMethodItemIcon,
    },
})
export default class PaymentMethodItem extends Vue {
    @Prop() method!: PaymentMethod;
    @Prop({ type: Boolean }) selected!: boolean;
    @Prop() selectedMethod!: CartPaymentMethod;
    @Prop() selectedIssuer!: PaymentMethodIssuer;

    get paymentMethodFeeSlug(): string {
        if (this.method.pricing.fees.fixed > 0) {
            if (this.method.pricing.fees.percentage > 0) {
                return 'shop.components.payment_method_item.fees.fixed_percentage';
            } else {
                return 'shop.components.payment_method_item.fees.fixed';
            }
        }

        if (this.method.pricing.fees.percentage > 0) {
            return 'shop.components.payment_method_item.fees.percentage';
        }

        return 'shop.components.payment_method_item.fees.none';
    }

    created(): void {
        if (this.selected && this.method.stripe) {
            this.stripe();
        }
    }

    get options(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        for (const issuer of this.method.issuers) {
            map[issuer.id] = issuer.name;
        }
        return map;
    }

    stripe(): void {
        this.$nextTick(async () => {
            await this.$shop.cart.mountStripePaymentMethod('#stripe-container');
        });
    }

    selectIssuer(guid: string): void {
        this.$emit('set-issuer', guid);
    }

    @Watch('selected')
    onSelectedChange(): void {
        if (this.selected && this.method.stripe) {
            this.stripe();
        }
    }
}
