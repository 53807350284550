

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CartTicket, ProductGroup, ShopProduct } from '@openticket/sdk-shop';

interface CheckboxGroupOptions {
    label: string;
    suffix: string;
    description?: string;
}

@Component
export default class OptionalProductGroupCheckboxItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;

    checked: string[] = [];
    productListener!: string;

    created(): void {
        this.updateChecked();

        this.productListener = this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product'],
            () => {
                this.updateChecked();
            }
        );
    }

    destroyed(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    get options(): { [key: string]: CheckboxGroupOptions } {
        const map: { [key: string]: CheckboxGroupOptions } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] = {
                label: product.item.name,
                suffix: this.$l.currency(
                    // The pricing should come from the ShopProduct wrapper,
                    // the GroupProduct item's pricing is static,
                    // for container products, the ShopProduct's pricing will change
                    // depending on the products in the cart!
                    (
                        this.ticket.item.products.all.find(
                            (ticketProduct: ShopProduct) =>
                                ticketProduct.guid === product.guid
                        ) || product.item
                    ).pricing.total_price,
                    this.$shop.data.currency as string
                ),
                description: product.item.description,
            };
        }
        return map;
    }

    updateChecked(): void {
        const checked: string[] = [];
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    checked.push(product.guid);
                }
            }
        }
        this.checked = checked;
    }

    @Watch('checked')
    onValueChanged(newValue: string[], oldValue: string[]): void {
        for (const guid of [...newValue, ...oldValue]) {
            if (!newValue.includes(guid)) {
                this.$emit('remove', guid);
            } else if (!oldValue.includes(guid)) {
                this.$emit('add', guid);
            }
        }
    }
}
