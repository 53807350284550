var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-item",class:_vm.status},[_c('div',{staticClass:"group-item__container"},[_c('div',{staticClass:"group-item__info ot-clickable",class:{ 'group-item__info--description': _vm.description },on:{"click":function($event){_vm.description && !_vm.disableDescription
                    ? (_vm.showDescription = !_vm.showDescription)
                    : null}}},[_c('span',{staticClass:"ot-text-body-strong"},[_vm._v(" "+_vm._s(_vm.title)+" "),(
                        _vm.description &&
                            !_vm.disableDescription &&
                            !_vm.showDescription
                    )?_c('img',{staticClass:"group-item__info__btn",attrs:{"src":require("@/assets/images/icons/info.svg"),"alt":_vm.$t(
                            'shop.components.shop_group_item.description.info_hover'
                        )}}):_vm._e()]),(_vm.pricing.total_price)?_c('span',{staticClass:"ot-text-tiny"},[_vm._v(" "+_vm._s(_vm.$t('shop.components.shop_group_item.item_price', { base_price: _vm.$l.currency( _vm.pricing.base_price, _vm.$shop.data.currency ), subtotal_price: _vm.$l.currency( _vm.pricing.subtotal_price, _vm.$shop.data.currency ), total_price: _vm.$l.currency( _vm.pricing.total_price, _vm.$shop.data.currency ), }))+" "+_vm._s(_vm.pricing.total_fees ? _vm.$t('shop.components.shop_group_item.item_fee', { total_fees: _vm.$l.currency( _vm.pricing.total_fees, _vm.$shop.data.currency ), }) : null)+" ")]):_c('span',{staticClass:"ot-text-tiny"},[_vm._v(_vm._s(_vm.$t('shop.components.shop_group_item.item_free')))])]),_c('div',{staticClass:"group-item__right"},[(_vm.status === 'available' || _vm.count > 0)?_c('div',[(_vm.type === 'default')?_c('div',{staticClass:"group-item__actions"},[_c('button',{staticClass:"\n                            ot-button\n                            is-round is-tiny\n                            group-item__actions__addition\n                        ",attrs:{"disabled":_vm.count < 1},on:{"click":_vm._remove}},[_c('i',{staticClass:"oti oti-minus is-small",attrs:{"alt":_vm.$t(
                                    'shop.components.shop_group_item.addition.remove'
                                )}})]),_c('span',[_vm._v(_vm._s(_vm.count))]),_c('button',{staticClass:"\n                            ot-button\n                            is-round is-tiny\n                            group-item__actions__addition\n                        ",attrs:{"disabled":_vm.count > 0 && _vm.status !== 'available'},on:{"click":_vm._add}},[_c('i',{staticClass:"oti oti-plus is-small",attrs:{"alt":_vm.$t(
                                    'shop.components.shop_group_item.addition.add'
                                )}})])]):(_vm.type === 'seated')?_c('div',{staticClass:"group-item__actions"},[_c('button',{staticClass:"ot-button is-small",on:{"click":_vm.open}},[_vm._v(" "+_vm._s(_vm.$t( 'shop.components.shop_group_item.seated.select' ))+" ")])]):_vm._e()]):_vm._e(),((_vm.status === 'available' || _vm.count > 0) && !_vm.isLoading)?_c('div',{staticClass:"group-item__price"},[(_vm.price > 0)?_c('span',{staticClass:"group-item__price__split"},_vm._l((_vm.counts),function(count,price){return _c('span',{key:price},[_vm._v(_vm._s(count)+" x "+_vm._s(_vm.$l.currency( parseInt(price), _vm.$shop.data.currency )))])}),0):_vm._e(),_c('span',{staticClass:"group-item__price__total",class:{
                        disabled: _vm.count <= 0,
                    }},[_vm._v(_vm._s(_vm.$l.currency(_vm.price, _vm.$shop.data.currency)))])]):(_vm.countdown)?_c('div',{staticClass:"group-item__status"},[_vm._v(" "+_vm._s(_vm.countdownString)+" ")]):(
                    !_vm.countdown && _vm.count < 1 && _vm.status !== 'available'
                )?_c('div',{staticClass:"group-item__status"},[_c('h5',[_vm._v(_vm._s(_vm.$t(_vm.statusSlug)))])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"group-item__loading"},[_c('div',{staticClass:"spinner"})]):_vm._e()])]),_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDescription),expression:"showDescription"}],on:{"click":function($event){_vm.showDescription = false}}},[_c('div',{staticClass:"group-item__description__container"},[_c('div',{staticClass:"group-item__description__content",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('div',{staticClass:"group-item__description__close"},[_c('i',{staticClass:"oti oti-close"}),_vm._v(" "+_vm._s(_vm.$t( 'shop.components.shop_group_item.description.close' ))+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }