
























































































































































import { AddProductEvent, ShopProduct } from '@openticket/sdk-shop';
import Component from 'vue-class-component';
import ShopModuleComponent from '../module';
import { getGlobalProduct } from './utils';

const CLASS_NAME = 'RefundProtect';

@Component
export default class GreenProduct extends ShopModuleComponent {
    checked = false;
    cartProductListener!: string;
    dataProductListener!: string;
    cartListener!: string;

    loading = false;

    internalPrice = 0;

    created(): void {
        if (!this.product) {
            return;
        }

        this.cartProductListener = this.$shop.cart.on(
            ['product', this.product.guid],
            () => this.updateChecked()
        );

        this.dataProductListener = this.$shop.on(
            ['product', this.product.guid],
            () => this.updateChecked()
        );

        // Due to pricing updates not firing events in the SDK,
        // we refresh the state on every cart change
        this.cartListener = this.$shop.on(
            'cart',
            () => this.updateChecked(),
            true
        );

        this.updateChecked();
    }

    beforeDestroy(): void {
        if (this.cartProductListener) {
            this.$shop.cart.off(this.cartProductListener);
        }

        if (this.dataProductListener) {
            this.$shop.cart.off(this.dataProductListener);
        }

        if (this.cartListener) {
            this.$shop.cart.off(this.cartListener);
        }
    }

    get orderIsPaid(): boolean {
        return this.$shop.cart.checkout_details.total_price > 0;
    }

    get product(): ShopProduct | undefined {
        return getGlobalProduct(
            this.$shop.data,
            CLASS_NAME,
            this.$route.params.eventId
        );
    }

    get price(): string {
        if (this.internalPrice === 0) {
            return '';
        }

        return `+ ${this.$l.currency(
            this.internalPrice,
            this.$shop.data.currency as string
        )}`;
    }

    updateChecked(): void {
        const info: AddProductEvent = this.$shop.cart.getProductInfo(
            this.product!.guid
        );

        this.internalPrice = info.pricing.total_price;
        this.checked = info.count > 0;
    }

    async selectRefundProtect(): Promise<void> {
        if (this.checked) {
            return;
        }

        return this.onValueChanged(true);
    }

    async deselectRefundProtect(): Promise<void> {
        if (!this.checked) {
            return;
        }

        return this.onValueChanged(false);
    }

    async onValueChanged(value: boolean): Promise<void> {
        this.loading = true;

        try {
            if (value) {
                await this.$shop.cart.addProduct(this.product!.guid);
            } else {
                await this.$shop.cart.removeProduct(this.product!.guid);
            }
        } finally {
            this.loading = false;
        }
    }
}
